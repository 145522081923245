import '../App.css';
import { ListingVals } from './ListingVals';
import { Index } from './Index';

import { useEffect, useState, createRef } from 'react';
import { Balance } from './Balance';


//make an array with the top 4 object of an array with the highest price_change_percentage_24h property
let getMaxMinCryptos = (arr) => {

  //sort an array by value
  arr.sort((a, b) => {
    return a.price_change_percentage_24h - b.price_change_percentage_24h;
  });

  //take the first 4 element of the array
  let min = [arr[0], arr[1], arr[2], arr[3], arr[4]];

  //sort an array by value
  arr.sort((b, a) => {
    return a.price_change_percentage_24h - b.price_change_percentage_24h;
  });

  //take the first 4 element of the array
  let max = [arr[0], arr[1], arr[2], arr[3], arr[4]];

  return [max, min.reverse()];

}




// fetch("https://www.ndtv.com/business", { mode: "no-cors" }).then(res => console.log(res));

//https://etoro-cdn.etorostatic.com/market-avatars/amzn/150x150.png

let manageStocks = (arr) => {

  let wins = [];
  let loses = [];
  for (let i = 0; i < 5; i++) {
    wins.push({ ...arr.gainers[i], price_change_percentage_24h: arr.gainers[i].percentNetChange, symbol: arr.gainers[i].ticker, image: "https://etoro-cdn.etorostatic.com/market-avatars/" + arr.gainers[i].ticker.toLowerCase() + "/150x150.png" });
    loses.push({ ...arr.losers[i], price_change_percentage_24h: arr.losers[i].percentNetChange, symbol: arr.losers[i].ticker, image: "https://etoro-cdn.etorostatic.com/market-avatars/" + arr.losers[i].ticker.toLowerCase() + "/150x150.png" });
  }

  return [wins, loses.reverse()];

}


export function Post({ theme }) {

  const [cryptos, setCryptos] = useState([[], []]);
  
  useEffect(() => {
    if (theme === "CRYPTOS") {
      let getCryptos = async () => {

        const options = {
          method: 'GET',
          headers: {
            'X-RapidAPI-Host': 'coingecko.p.rapidapi.com',
            'X-RapidAPI-Key': '2859afaacamsh1dc7ee3bab7cc57p1cbbc7jsn7407c446fb51'
          }
        };

        let res = await fetch('https://coingecko.p.rapidapi.com/coins/markets?vs_currency=usd&price_change_percentage=24h&page=1&per_page=100&order=market_cap_desc', options)
        res = await res.json();
        setCryptos(getMaxMinCryptos(res));

      }

      getCryptos();
    } else if (theme == "STOCKS") {
      let getStocks = async () => {

        const options = {
          method: 'GET',
          headers: {
            'X-RapidAPI-Host': 'ms-finance.p.rapidapi.com',
            'X-RapidAPI-Key': '2859afaacamsh1dc7ee3bab7cc57p1cbbc7jsn7407c446fb51'
          }
        };

        let res = await fetch('https://ms-finance.p.rapidapi.com/market/v2/get-movers', options)
        res = await res.json();
        setCryptos(manageStocks(res));

      }

      getStocks();
    }
  }, [])



  return (
    <>

      <div className="App" style={{ background: "#151515", overflow: "hidden" }}>
        <div>
          <p style={{ fontSize: 40, margin: 0 }}> Recap of the day </p>
          <p style={{
            fontWeight: "bold", lineHeight: "60px",
            marginTop: 3, fontSize: 70, margin: 0, marginTop: -15
          }}> {theme} </p>
        </div>

        {theme === "STOCKS" || theme === "CRYPTOS" ? <div className="divide2">
          <div className="pot">
            {cryptos[0].map((e, i) => <ListingVals stockName={theme === "STOCKS" ? e.name : null} index={i} first={Math.round(10 * cryptos[0][0].price_change_percentage_24h) / 10} url={e.image} name={"$" + e.symbol.toUpperCase()} pourcent={Math.round(10 * e.price_change_percentage_24h) / 10} />)}
          </div>
          <div className="pot">
            {cryptos[1].map((e, i) => <ListingVals stockName={theme === "STOCKS" ? e.name : null} index={i} first={Math.round(10 * cryptos[1][3].price_change_percentage_24h) / 10} url={e.image} name={"$" + e.symbol.toUpperCase()} pourcent={Math.round(10 * e.price_change_percentage_24h) / 10} right={true} />)}        </div>
        </div> : theme === "INDEXES" ?
          <Index /> : <Balance />
        }
        <p style={{ margin: 0, marginTop: 30, opacity: 0.4, fontSize: 15 }}> By @finance_daily_recap, like & follow to support our work! </p>
      </div>

    </>
  );
}
