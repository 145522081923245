import React, { useEffect, useState } from 'react'
import  crypto  from "../crypto.png"
import  stocks  from "../stocks.png"
import  realestate  from "../realestate.png"

const indexes = [{
    "country": "united states",
    "name": "S&P 500",
    "full_name": "S&P 500",
    "symbol": "SPX",
    "currency": "USD",
    "class": "major_indices",
    "market": "world_indices",
},
{
    "country": "united states",
    "name": "DJ Global ex-US Select Real Estate",
    "full_name": "Dow Jones Global ex-US Select Real Estate Securities",
    "symbol": "DWXRS",
    "currency": "USD",
    "class": "other_indices",
    "market": "global_indices",
}
];
/**
{
    "country": "euro zone",
    "name": "FTSE EUROFIRST 100 Market",
    "full_name": "FTSE EUROFIRST 100 Market",
    "symbol": "FTEF100C1R",
    "currency": "EUR",
    "class": "other_indices",
    "market": "global_indices",
},
{
    "country": "china",
    "name": "S&P/CITIC300",
    "full_name": "S&P/CITIC300",
    "symbol": "SPCITIC300",
    "currency": "CNY",
    "class": "additional_indices",
    "market": "world_indices",
},
{
    "country": "brazil",
    "name": "Brazil 50",
    "full_name": "Bovespa Brazil 50",
    "symbol": "IBX50",
    "currency": "BRL",
    "class": "additional_indices",
    "market": "world_indices",
}]; */

const url = {

    "Cryptos Total MarketCap": crypto,
    "DJ Global Real Estate" : realestate,
    "S&P 500": stocks
};

export const Index = () => {

    const [indexs, setIndexs] = useState([]);

    useEffect(() => {

        let fetching = async () => {

            let tab = [];

            let options = {
                method: 'GET',
                headers: {
                    'X-RapidAPI-Host': 'coingecko.p.rapidapi.com',
                    'X-RapidAPI-Key': '2859afaacamsh1dc7ee3bab7cc57p1cbbc7jsn7407c446fb51'
                }
            };

            let cryptoIndex = await fetch('https://coingecko.p.rapidapi.com/global', options)
            cryptoIndex = await cryptoIndex.json();

            let cryptoIndexs = { name: "Cryptos Total MarketCap", pourcent: Math.round(10 * cryptoIndex.data.market_cap_change_percentage_24h_usd) / 10 };

            options = {
                method: 'GET',
                headers: {
                    'X-RapidAPI-Host': 'global-market-data.p.rapidapi.com',
                    'X-RapidAPI-Key': '2859afaacamsh1dc7ee3bab7cc57p1cbbc7jsn7407c446fb51'
                }
            };

            let sp = await fetch('https://global-market-data.p.rapidapi.com/index/info?index=S%26P%20500&country=united%20states', options);
            sp = await sp.json();

            let home = await fetch('https://global-market-data.p.rapidapi.com/index/info?index=DJ%20Global%20ex-US%20Select%20Real%20Estate&country=united%20states', options);
            home = await home.json();

            console.log(sp, home);


            tab.push(cryptoIndexs);
            tab.push({ name: "S&P 500", pourcent: Math.round(10 * (((100 * sp.prev_close) / sp.open) - 100)) / 10 });
            tab.push({ name: "DJ Global Real Estate", pourcent: Math.round(10 * (((100 * home.prev_close) / home.open) - 100)) / 10 });

            //sort the tab variable by pourcent
            tab.sort((a, b) => {
                return b.pourcent - a.pourcent;
            });

            setIndexs(tab);
        }

        fetching();
    }, [])


    return (
        <div style={{ width: "80%", marginTop: 35 }}>
            {indexs.map((e, i) =>
                <div className="nouv" style={{ marginBottom: i < indexs.length - 1 ? 14 : 0, }}>
                    <img src={url[e.name]} style={{ border: "2px solid white" }} />
                    <div style={{
                        width: ((4 - i) * 6 + 76) + "%", textAlign: "left", background: e.pourcent < 0 ? '#F55353' : '#70FF2E',
                        // boxShadow: `0 0 16px 5px ${e.pourcent < 0 ? '#F55353' : '#70FF2E'}`
                    }}>
                        <span style={{ marginLeft: 38 }}> {e.name} </span>
                        <span style={{ color: "rgba(0,0,0,0.4)", marginRight: 16 }}> {e.pourcent}% </span>
                    </div>
                </div>)}
        </div>
    )
}
