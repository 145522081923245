import React from 'react'


//cut a string to a certain length
function cutString(str, length) {
  if (str.length > length) {
    return str.substring(0, length);
  }
  return str;
}

export const ListingVals = ({ index, url, right = false, pourcent, name, stockName }) => {


  let calc = right ? index * 4 : (4 - index) * 4;
  calc += 80;

  return (
    <>
      {right ?
        <div className="nouv2">

          <div style={{ width: calc + "%", }}>
            <span style={{ color: "rgba(0,0,0,0.4)", marginLeft: 16 }}> {pourcent}% </span>
            {stockName ?
              <div style={{ marginRight: 34, display: 'flex', flexDirection: "column", alignItems: "flex-end" }}>
                <p style={{ color: "black", margin: 0 }}> {cutString(name, 4)} </p>
                <p style={{ color: "rgba(0,0,0,0.6)", textAlign:"right", fontSize: 7, margin: 0, width: 56, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", height: 12, marginTop: -3 }}> {stockName} </p>
              </div> :
            <span style={{ marginRight: 38, overflow: "hidden" }}> {cutString(name, 4)} </span>
            }
          </div>
          <img src={url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "https://avatars.dicebear.com/api/adventurer-neutral/" + Math.random() + ".svg";
            }}
          />
        </div>
        : <div className="nouv">
          <img src={url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "https://avatars.dicebear.com/api/adventurer-neutral/" + Math.random() + ".svg";
            }}
          />
          <div style={{ width: calc + "%", textAlign: "left" }}>
            {stockName ?
              <div style={{ marginLeft: 38, display: 'flex', flexDirection: "column" }}>
                <p style={{ color: "black", margin: 0 }}> {cutString(name, 4)} </p>
                <p style={{ color: "rgba(0,0,0,0.6)", fontSize: 7, margin: 0, width: 60, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", height: 12, marginTop: -3 }}> {stockName} </p>
              </div> :
              <span style={{ marginLeft: 38 }}> {cutString(name, 4)} </span>}
            <span style={{ color: "rgba(0,0,0,0.4)", marginRight: 16 }}> +{pourcent}% </span>
          </div>
        </div>

      }
    </>
  )
}
