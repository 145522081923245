import React, {useRef} from 'react'
import {Post} from "./components/Post";
import html2canvas from "html2canvas";


let themes = ["CRYPTOS", "STOCKS",  "INDEXES", "MARKET CONFIDENCE"];
// themes = ["STOCKS"]

export default function App() {

  let refs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  
  const exportAsImage = async (el, imageFileName) => {
    console.log(el);
    const canvas = await html2canvas(el, {
      logging: true, letterRendering: 1, 
      // allowTaint: false,
      useCORS: true,
      onrendered: function (canvas) {
           var a = document.createElement('a');
           a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
           a.download = 'test.jpg';
           a.click();
         }
      });
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, imageFileName);
  };
  
  const downloadImage = (blob, fileName) => {

    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;
    fakeLink.href = blob;
    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);
    
    fakeLink.remove();
  };

  let exporting = () => {
    exportAsImage(refs[0].current, "gainers.png");
    exportAsImage(refs[1].current, "losers.png");
    exportAsImage(refs[2].current, "indexes.png");
    exportAsImage(refs[3].current, "market-confidence.png");
  }

  return (
    <>
      <button onClick={exporting}>Export as image</button>
      {themes.map((e,i) => <div ref={refs[i]}> <Post theme={e} /> </div>)}
    </>
  )
}
