import React, { useEffect, useState } from 'react'

const fears = { "EXTREME FEAR": "rgb(0,0,0,0)", "FEAR": "rgb(0,0,0,0)", "NEUTRAL": "rgb(0,0,0,0)", "GREED": "rgb(0,0,0,0)", "EXTREME GREED": "rgb(0,0,0,0)" };

export const Balance = () => {

    const [balance, setBalance] = useState(null);

    useEffect(() => {
        
        let fetching = async () => {
            const options = {
                method: 'GET',
                headers: {
                    'X-RapidAPI-Host': 'fear-and-greed-index.p.rapidapi.com',
                    'X-RapidAPI-Key': '2859afaacamsh1dc7ee3bab7cc57p1cbbc7jsn7407c446fb51'
                }
            };
            
            console.log("AHAHZ");
            let res = await fetch('https://fear-and-greed-index.p.rapidapi.com/v1/fgi', options);
            console.log("BBBB");
            res = await res.json();
            console.log(res);

            setBalance(res.fgi.now);
        }

        fetching();

    }, [])

    if(!balance) return null;

    return (
        <>
            <div className="balance">

                <div className="balance-item" style={{ left: `${balance.value}%` }}>
                    {balance.value}
                </div>

            </div>

            <p className="fear" style={{ color: `${fears[balance.valueText.toUpperCase()]} !important` }}> {balance.valueText.toUpperCase()} </p>

        </>
    )
}
